<template>
  <div class="manage-agency-edit animatedBox">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm">
        <div class="card p-2 mb-2">
          <h4 class="mainTitle pt-3 pb-4">Edit Agency</h4>
          <div class="row">
            <fd-input
              v-model="agencyData.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Agency Name"
              name="name"
              type="text"
              :validators="[
                validator.required,
                (val) => validator.minLength(val, 3)
              ]"
            >
            </fd-input>
            <fd-input
              v-model="agencyData.personInChargeName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Person In Charge"
              name="personInChargeName"
              type="text"
              :validators="[
                validator.required,
                (val) => validator.minLength(val, 3)
              ]"
            >
            </fd-input>
            <fd-input
              v-model="agencyData.personInChargeContact"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Person In Charge Contact"
              name="personInChargeContact"
              type="text"
              :validators="[validator.required, validator.phone]"
            >
            </fd-input>
            <fd-input
              v-model="agencyData.website"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Website"
              name="website"
              type="text"
              :validators="[validator.website]"
            >
            </fd-input>
            <div class="col-12 px-1 mb-2 main-photo">
              <label class="label">Agency Logo</label>
              <image-uploader
                v-model="agencyData.logo"
                :multiple="false"
                @error="
                  (error, imageName) => {
                    $reportError(error, 'Upload logo (Edit Agency)');
                  }
                "
              >
              </image-uploader>
            </div>
          </div>
          <div class="text-right p-2">
            <button class="btn main">Update</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  phone,
  website,
  minLength
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { AgencyModel } from "@/models";

export default {
  name: "manage-agency-edit",
  components: {
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader")
  },
  data() {
    return {
      agencyData: {
        name: "",
        logo: [],
        website: "",
        personInChargeName: "",
        personInChargeContact: ""
      },
      validator: {
        required: required,
        phone: phone,
        website: website,
        minLength: minLength
      }
    };
  },
  mounted() {
    this.getAgency();
  },
  methods: {
    async getAgency() {
      this.$store.commit("setIsLoading", true);
      try {
        let data = await this.$store.dispatch("manageAgency/getAgency", {
          id: this.$route.params.id
        });

        this.agencyData = AgencyModel.getAgencyDetailResponse(
          this._.cloneDeep(data)
        );

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    validateFail() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Invalid Input",
        text: "Please check if the inputs are valid."
      });
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        const request = await this.$store.dispatch(
          "manageAgency/updateAgency",
          {
            id: this.$route.params.id,
            payload: AgencyModel.postAgencyDetailPayload(this.agencyData)
          }
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Agency is updated successfully."
        });
        this.$router.push({ name: "ManageAgency" });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.warn(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-agency-edit {
  form {
    @extend %formDesign;
  }
}
</style>
